import React, { useEffect, useState } from "react";
import NavbarType from "../types/Navbar.type";
import Layout from "../components/layout";
import MaxWidthWrapper from "../components/common/MaxWidthWrapper";
import SecondaryWidthWrapper from "../components/common/SecondaryWidthWrapper";
import Select from "react-select";
import axios from "axios";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import SeoDataHandler from "../helper/handler/seoData.handler";
import { SEO } from "../components/seo";
import LoaderSvg from "../components/svg/loader.svg";
import "../css/contact-propelius-technology.css";
import { City } from "country-state-city";

interface PageContextProps {
  common: {
    navbarData: NavbarType;
    footerData: any;
  };
  contactPageData: any;
  experience: Array<{ id: number; attributes: { label: string } }>;
  qualification: Array<{ id: number; attributes: { label: string } }>;
  availableJobProfile: Array<{
    id: number;
    attributes: { jobTitle: string };
  }>;
}

interface ContactPropeliusTechnologyProps {
  pageContext: PageContextProps;
}

const lookingForData = [
  { value: "Onsite", label: "Onsite" },
  { value: "Remote", label: "Remote" },
];

const ContactPropeliusTechnology: React.FC<ContactPropeliusTechnologyProps> = ({
  pageContext,
}) => {
  const customStyles = {
    placeholder: (defaultStyles: any) => {
      return {
        ...defaultStyles,
        color: "#9CA4B7",
      };
    },
    control: (provided: any, _: any) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
      color: "red",
      textOverflow: "ellipsis",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#40BA77" : "white",
      boxBorder: "1px solid #40BA77",
      "&:hover": {
        backgroundColor: "#40BA77",
        color: "white",
      },
    }),
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const formDataInitialVal = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    experience: null,
    applieFor: null,
    highestQualification: null,
    linkedProfile: "",
    token: "",
    lookingFor: null,
    currentLocation: null,
  };
  const [formData, setFormData] = useState<{
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    experience: { value: string; label: string } | null;
    applieFor: { value: string; label: string } | null;
    highestQualification: { value: string; label: string } | null;
    linkedProfile: string;
    token: string;
    lookingFor: { value: string; label: string } | null;
    currentLocation: { value: string; label: string } | null;
  }>(formDataInitialVal);

  const [availableJob, setAvailableJob] = useState<
    { value: string; label: string }[]
  >([{ value: "", label: "" }]);
  const [experience, setExperience] = useState<
    { value: string; label: string }[]
  >([{ value: "", label: "" }]);
  const [qualification, setQualification] = useState<
    {
      value: string;
      label: string;
    }[]
  >([{ value: "", label: "" }]);
  const [fileUploaded, setFileUploaded] = useState<FileList | File[] | null>(
    null
  );
  const [btnText, setBtnText] = useState<string>("Submit");
  const [lookingFor, setLookingFor] = useState<
    { value: string; label: string }[]
  >([{ value: "", label: "" }]);
  const [currentLocation, setCurrentLocation] = useState<
    { value: string; label: string }[]
  >([{ value: "", label: "" }]);

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const experienceData = pageContext?.experience.map((item) => ({
      value: item.attributes.label,
      label: item.attributes.label,
    }));

    const qualificationData = pageContext?.qualification.map((item) => ({
      value: item.attributes.label,
      label: item.attributes.label,
    }));

    const availableJobData = pageContext?.availableJobProfile.map((item) => ({
      value: item.attributes.jobTitle,
      label: item.attributes.jobTitle,
    }));

    setAvailableJob(availableJobData);
    setExperience(experienceData);
    setQualification(qualificationData);
    setLookingFor(lookingForData);
    const allCitiesData = City.getCitiesOfCountry("IN");
    if (allCitiesData) {
      setCurrentLocation(
        allCitiesData.map((item) => ({
          value: item.name,
          label: item.name,
        }))
      );
    }
  }, []);

  const handleChange = (event: any, field: string) => {
    setFormData((prevState) => {
      const temp = prevState;
      if (
        field === "experience" ||
        field === "applieFor" ||
        field === "highestQualification" ||
        field === "lookingFor" ||
        field === "currentLocation"
      ) {
        return { ...temp, [field]: { value: event.value, label: event.value } };
      }
      return { ...temp, [field]: event.target.value };
    });
  };
  const isValidate = (event: any) => {
    if (!formData.firstName.trim()) {
      setErrorMsg("First name is required");
      return false;
    }

    if (!formData.lastName.trim()) {
      setErrorMsg("Last name is required");
      return false;
    }

    if (!formData.email) {
      setErrorMsg("Email is required");
      return false;
    } else if (
      !formData.email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setErrorMsg("Email not valid");
      return false;
    }

    if (!formData.phone) {
      setErrorMsg("Phone is required");
      return false;
    } else if (
      !formData.phone.match(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
      )
    ) {
      setErrorMsg("Invalid phone number");
      return false;
    }

    if (!formData.applieFor) {
      setErrorMsg("Job profile is required");
      return false;
    }

    if (!formData.experience) {
      setErrorMsg("Experience is required");
      return false;
    }

    if (!formData.currentLocation) {
      setErrorMsg("Current location is required");
      return false;
    }

    if (!formData.lookingFor) {
      setErrorMsg("Looking for is required");
      return false;
    }

    if (!formData.highestQualification) {
      setErrorMsg("Qualification is required");
      return false;
    }

    if (!event.target.elements.cv.files.length) {
      setErrorMsg("Upload you latest resume");
      return false;
    }

    const files: any = Array.from(event.target.elements.cv.files);

    if (files[0].size > 2097152) {
      setErrorMsg("File should not be more than 2MB");
      return false;
    }

    const linkExpress =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    if (formData.linkedProfile && !formData.linkedProfile.match(linkExpress)) {
      setErrorMsg("Invalid Linkedin profile");
      return false;
    }

    if (!executeRecaptcha) {
      setErrorMsg("Something went wrong");
      return false;
    }
    return true;
  };

  const handleSubmit = async (event: any) => {
    setIsLoading(true);
    event.preventDefault();

    const bodyFormData = new FormData();

    const isValid = isValidate(event);

    if (!isValid) {
      setIsLoading(false);
      return;
    }

    setErrorMsg("");

    if (executeRecaptcha) {
      formData.token = await executeRecaptcha("contact_application");
    }

    const {
      experience,
      applieFor,
      currentLocation,
      lookingFor,
      highestQualification,
      ...rest
    } = formData;
    const temp = {
      experience: experience?.value || experience,
      applieFor: applieFor?.value || applieFor,
      highestQualification: highestQualification?.value || highestQualification,
      currentLocation: currentLocation?.value || currentLocation,
      lookingFor: lookingFor?.value || lookingFor,
      ...rest,
    };

    bodyFormData.append("data", JSON.stringify(temp));
    if (fileUploaded?.[0]) bodyFormData.append("files.cv", fileUploaded[0]);

    axios
      .post(
        `${process.env.GATSBY_API_URL}/candidate-applications`,
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${process.env.GATSBY_API_KEY}`,
          },
        }
      )
      .then((data) => {
        if (data.data) {
          setSuccessMsg("we'll get back to you soon");
          setBtnText("Submitted");
          setTimeout(() => {
            setSuccessMsg("");
            setBtnText("Submit");
          }, 5000);
        }
        setFormData(formDataInitialVal);
        setFileUploaded(null);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setErrorMsg(
          "Something went wrong, please email your resume to hr@propelius.tech"
        );
        setTimeout(() => {
          setErrorMsg("");
        }, 4000);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOnUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileUploaded(e.target.files);
  };
  return (
    <Layout
      footerData={pageContext?.common?.footerData}
      navbarData={pageContext?.common?.navbarData}
    >
      <div>
        <MaxWidthWrapper>
          <SecondaryWidthWrapper>
            <div className={"pb-[80px] pt-[30px] lg:pt-[80px] lg:pb-[100px]"}>
              <div>
                <div className={""}>
                  <p
                    className={
                      "text-[32px] font-bold text-black-400 lg:text-[52px]"
                    }
                  >
                    Join a community,
                  </p>
                  <p
                    className={
                      "text-[32px] font-bold text-primary lg:text-[52px]"
                    }
                  >
                    not a company
                  </p>
                </div>
                <div>
                  <h5
                    className={
                      "text-[14px] text-black-400 md:text-[16px] lg:text-[18px]"
                    }
                  >
                    To apply, please take the time to fill out the information
                    below.
                  </h5>
                </div>
              </div>
              {/* constact form form*/}
              <div className={"justify-left flex"}>
                <form onSubmit={handleSubmit}>
                  <div
                    className={
                      "flex flex-wrap gap-x-[73px] lg:grid lg:grid-cols-2"
                    }
                  >
                    <input
                      className={
                        "mt-20 w-full border-b-[1px] border-b-primary bg-transparent p-1 focus:outline-none lg:w-[310px]"
                      }
                      name={"firstName"}
                      placeholder={"First name *"}
                      value={formData.firstName}
                      onChange={(e) => handleChange(e, e.target.name)}
                    />
                    <input
                      className={
                        "mt-20 w-full border-b-[1px] border-b-primary bg-transparent p-1 focus:outline-none lg:w-[310px]"
                      }
                      name={"lastName"}
                      placeholder={"Last name *"}
                      value={formData.lastName}
                      onChange={(e) => handleChange(e, e.target.name)}
                    />
                    <input
                      className={
                        "mt-12 w-full border-b-[1px] border-b-primary bg-transparent p-1 focus:outline-none lg:w-[310px] xl:mt-20"
                      }
                      name={"email"}
                      type={"email"}
                      placeholder={"Email *"}
                      value={formData.email}
                      onChange={(e) => handleChange(e, e.target.name)}
                    />
                    <input
                      className={
                        "mt-12 w-full border-b-[1px] border-b-primary bg-transparent p-1 focus:outline-none lg:w-[310px] xl:mt-20"
                      }
                      name={"phone"}
                      placeholder={"Phone *"}
                      type={"tel"}
                      value={formData.phone}
                      onChange={(e) => handleChange(e, e.target.name)}
                    />
                    <Select
                      className={
                        "mt-12 w-[100%] text-ellipsis border-b-[1px] border-b-primary bg-transparent p-1 focus:outline-none lg:w-[310px]"
                      }
                      options={availableJob}
                      placeholder={"Applying for *"}
                      value={formData.applieFor}
                      onChange={(e) => handleChange(e, "applieFor")}
                      name={"applieFor"}
                      styles={customStyles}
                    />
                    <Select
                      className={
                        "mt-12 w-full border-b-[1px] border-b-primary bg-transparent p-1 focus:outline-none lg:w-[310px] "
                      }
                      options={experience}
                      placeholder={"Select your experience *"}
                      name={"experience"}
                      value={formData.experience}
                      onChange={(e) => handleChange(e, "experience")}
                      // onChange={(e) => handleChange(e, "experience")}
                      styles={customStyles}
                    />
                    <Select
                      className={
                        "mt-12 w-full border-b-[1px] border-b-primary bg-transparent p-1 focus:outline-none lg:w-[310px] "
                      }
                      options={currentLocation}
                      placeholder={"Current location *"}
                      name={"currentLocation"}
                      value={formData.currentLocation}
                      onChange={(e) => handleChange(e, "currentLocation")}
                      // onChange={(e) => handleChange(e, "experience")}
                      styles={customStyles}
                    />
                    <Select
                      className={
                        "mt-12 w-full border-b-[1px] border-b-primary bg-transparent p-1 focus:outline-none lg:w-[310px] "
                      }
                      options={lookingFor}
                      placeholder={"Looking for *"}
                      name={"lookingFor"}
                      value={formData.lookingFor}
                      onChange={(e) => handleChange(e, "lookingFor")}
                      // onChange={(e) => handleChange(e, "experience")}
                      styles={customStyles}
                    />
                  </div>
                  <Select
                    className={
                      "mt-12 w-full border-b-[1px] border-primary border-b-primary bg-transparent p-1 focus:outline-none"
                    }
                    options={qualification}
                    placeholder={"Select your highest qualification *"}
                    name={"highestQualification"}
                    value={formData.highestQualification}
                    onChange={(e) => handleChange(e, "highestQualification")}
                    // onChange={(e) => handleChange(e, "highestQualification")}
                    styles={customStyles}
                  />
                  <input
                    className={
                      "mt-12 w-full border-b-[1px] border-b-primary bg-transparent p-1 focus:outline-none "
                    }
                    name={"linkedProfile"}
                    type={"text"}
                    value={formData.linkedProfile}
                    onChange={(e) => handleChange(e, "linkedProfile")}
                    placeholder={"Your linkedin profile"}
                  />
                  <div className="flex gap-4">
                    <label
                      onClick={() => document.getElementById("file")?.click()}
                      className="mt-12 block w-fit cursor-pointer rounded-full border-2 border-solid border-primary bg-white py-2 px-4  text-sm font-semibold  text-primary file:mr-4 file:cursor-pointer"
                    >
                      Upload Resume
                    </label>

                    <span className="mt-[54px] text-slate-500">
                      {fileUploaded && fileUploaded[0]?.name}
                    </span>
                  </div>
                  <input
                    id="file"
                    className={
                      "mt-12 hidden w-auto cursor-pointer text-sm text-slate-500 file:mr-4 file:cursor-pointer file:rounded-full file:border-2 file:border-solid file:border-primary file:bg-white file:py-2 file:px-4 file:text-sm file:font-semibold file:text-primary"
                    }
                    type="file"
                    name={"cv"}
                    onChange={(e) => handleOnUpload(e)}
                  />

                  <button
                    className={`gap[5px] mt-14 block flex items-center rounded ${
                      isLoading ? "bg-[#ababab]" : "bg-green-300"
                    }  px-9 py-2 font-semibold text-white drop-shadow-xs lg:px-14 lg:py-3 lg:text-[22px] xl:mt-16 `}
                    type={"submit"}
                    disabled={isLoading || btnText === "Submitted"}
                  >
                    {isLoading && <LoaderSvg width={"h-8"} height={"w-8"} />}
                    <span> {btnText} </span>
                  </button>
                  {successMsg && (
                    <p className={"mt-5 text-primary"}>{successMsg}</p>
                  )}
                  {errorMsg && (
                    <p className={"mt-5 text-red-600"}>{errorMsg}</p>
                  )}
                </form>
              </div>
            </div>
          </SecondaryWidthWrapper>
        </MaxWidthWrapper>
      </div>
    </Layout>
  );
};

export default ContactPropeliusTechnology;

export const Head: React.FC<any> = ({ pageContext }) => {
  const seoData = SeoDataHandler(pageContext?.contactPageData?.pageData);
  return (
    <SEO
      title={seoData.title}
      description={seoData.description}
      keywords={seoData.keywords}
      image={seoData.image}
      pathname={seoData.pagePath}
    />
  );
};
